const ZnsTemplateId = [
  {
    name: 'utm_source',
    type: 'string',
    label: 'UTM Source',
    isRequired: true,
  },
  {
    name: 'utm_medium',
    type: 'string',
    label: 'UTM Medium',
    isRequired: true,
  },
  {
    name: 'utm_campaign',
    type: 'string',
    label: 'UTM Campaign',
    isRequired: true,
  },
]

export default ZnsTemplateId

export type ZnsTemplateId = {
  title: string
  content: string
  image: string
  coupon_code: string
  button_name: string
  button_nav_screen: string
  utm_source: string
  utm_medium: string
  utm_campaign: string
}
