import React, { useEffect, useMemo } from 'react'
import { Table, Empty } from 'antd'

import LoadingIcon from 'Components/LoadingIcon'
import useTable from './useTable'
import Actions from './Actions'
import { needStringifyArray } from 'Utils'
import { set } from 'lodash'

const CustomTable = (props) => {
  const {
    columns: columnProps,
    fetchFrom,
    rowActions,
    queryObject,
    rowClassName,
    handleTableChange,
    defaultSort,
    customLoading,
    setDataCallback,
  } = props

  const { data, loading, total, setParams, params, setData }: any = useTable(fetchFrom)
  const checkIfFromCJ = useMemo(() => fetchFrom?.url === '/customer-journeys/', [fetchFrom])

  useEffect(() => {
    if (!queryObject || !Object.keys(queryObject).length) return
    if (checkIfFromCJ) {
      setParams({
        sortBy: defaultSort,
        perPage: 10,
        ...params,
        ...queryObject,
      })
    } else {
      setParams(
        needStringifyArray({
          order_by: defaultSort,
          per_page: 10,
          ...params,
          ...queryObject,
        })
      )
    }
  }, [queryObject, customLoading])

  useEffect(() => {
    if (setDataCallback && data) setDataCallback(data)
  }, [data])

  const renderActions = () => {
    if (!rowActions?.actions) return []
    return [
      {
        ...(rowActions.fixed && { fixed: rowActions.fixed }),
        dataIndex: 'action',
        key: 'x',
        width: 100,
        render: (text, record) => {
          return (
            rowActions?.actions && (
              <Actions actions={rowActions.actions} record={record} setRows={setData} rows={data} />
            )
          )
        },
      },
    ]
  }

  return (
    <Table
      className="TableComponent mt-4"
      dataSource={data}
      locale={{ emptyText: loading ? ' ' : <Empty></Empty> }}
      columns={[...columnProps, ...renderActions()]}
      pagination={{
        pageSizeOptions: ['5', '10', '20', '30', '50'],
        showSizeChanger: true,
        pageSize: checkIfFromCJ ? params?.perPage || 10 : params?.per_page || 10,
        total: parseInt(total) || undefined,
        current: params?.page || 1,
      }}
      loading={{
        spinning: customLoading ?? loading,
        indicator: <LoadingIcon />,
      }}
      rowClassName={rowClassName}
      rowKey={fetchFrom.recordKey || 'id'}
      onChange={handleTableChange}
      scroll={{ x: 1000 }}
      onRow={(record, rowIndex) => ({
        onDoubleClick: () => rowActions?.onDoubleClick && rowActions.onDoubleClick(record),
        onClick: () => rowActions?.onRowClick && rowActions.onRowClick(record),
      })}
    />
  )
}

export default CustomTable
