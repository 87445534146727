const SMS = [
  {
    name: 'message_content',
    type: 'dynamic_content',
    label: 'Content',
    isRequired: true,
  },
  {
    name: 'coupon_code_id',
    type: 'string',
    label: 'Coupon Campaign ID (Optional)',
    placeholder: 'Enter coupon campaign ID (from KFM Admin)',
    isRequired: false,
  },
]

export default SMS
