import React, { useState, useMemo, useEffect } from 'react'
import axios from 'Utils/axios'
import { formatErrors } from 'Utils'
import axiosRaw from 'axios'
import { message } from 'antd'
import cjAxios from 'Utils/cj-axios'
import { useDebounce } from 'Hooks'

const DEBOUNCE_TIMEOUT = 700

const useFetchTables = (urlInformation) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
    errors: null,
    total: '',
  })
  const [params, setParams] = useState<any>()
  const debouncedParams = useDebounce(params, DEBOUNCE_TIMEOUT)

  useEffect(() => {
    let cancelTokenSource = axiosRaw.CancelToken.source()

    const fetchTable = async () => {
      if (!params) return

      setState((prev) => ({ ...prev, loading: true, errors: null }))
      try {
        let result

        if (urlInformation.server === 'cj') {
          result = await cjAxios.get(urlInformation?.url, {
            params,
            cancelToken: cancelTokenSource.token,
          })
        } else {
          result = await axios.get(urlInformation?.url, {
            params,
            cancelToken: cancelTokenSource.token,
          })
        }

        if (result.status !== 200) throw new Error()

        if (urlInformation.key) {
          setState((prev) => ({
            ...prev,
            data: result?.data[urlInformation.key],
            loading: false,
            total: result?.data.count,
          }))
        } else {
          setState((prev) => ({
            ...prev,
            data: result?.data,
            loading: false,
            total: result?.data?.count,
          }))
        }
      } catch (error: any) {
        if (error?.message === 'Cancel previous request') return

        const err =
          typeof formatErrors(error?.response?.data) === 'string'
            ? formatErrors(error?.response?.data)
            : error?.message || 'Something went wrong'

        // @ts-ignore
        setState((prev) => ({
          ...prev,
          loading: false,
          errors: err,
        }))
        message.error(err)
      }
    }

    if (debouncedParams) fetchTable()

    return () => {
      cancelTokenSource.cancel('Cancel previous request')
      // avoid race condition
    }
  }, [debouncedParams])

  return {
    ...state,
    params,
    setParams,
    setData: (data) => setState({ ...state, data }),
    setLoading: (boolean) => setState({ ...state, loading: boolean }),
  }
}

export default useFetchTables
