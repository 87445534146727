const webPush = [
  {
    name: 'image',
    type: 'image',
    label: 'Image',
    isRequired: true,
  },
  {
    name: 'logo',
    type: 'image',
    label: 'Logo',
    isRequired: false,
  },
  {
    name: 'dynamic_link',
    type: 'select',
    label: 'Dynamic Link',
    tooltip: 'Not working with broadcast or insider token!',
    options: [
      {
        code: 'none',
        name: 'None',
      },
      {
        code: 'last_viewed_product',
        name: 'Last viewed product',
      },
      {
        code: 'last_viewed_category',
        name: 'Last viewed category',
      },
      {
        code: 'recommend_product_top_sale_in_last_30_days',
        name: 'Recommend product top sale in last 30 days',
      },
      {
        code: 'most_viewed_category',
        name: 'Most viewed category',
      },
      {
        code: 'product_in_cart',
        name: 'Product in cart',
      },
    ],
    isRequired: true,
  },
  {
    name: 'actions',
    type: 'dependent_selects',
    label: 'Action buttons',
    options: [
      {
        type: 'static_url',
        label: 'Static link',
        sub_options: null,
      },
      {
        type: 'dynamic_link',
        label: 'Dynamic link',
        tooltip: 'Not working with broadcast or insider token!',
        sub_options: [
          {
            code: 'last_viewed_product',
            name: 'Last viewed product',
          },
          {
            code: 'last_viewed_category',
            name: 'Last viewed category',
          },
          {
            code: 'recommend_product_top_sale_in_last_30_days',
            name: 'Recommend product top sale in last 30 days',
          },
          {
            code: 'most_viewed_category',
            name: 'Most viewed category',
          },
          {
            code: 'product_in_cart',
            name: 'Product in cart',
          },
        ],
      },
    ],
    isRequired: false,
  },
  {
    name: 'link',
    type: 'string',
    label: 'Open Link',
    isRequired: true,
  },
  {
    name: 'title',
    type: 'dynamic_content',
    label: 'Title',
    isRequired: true,
  },
  {
    name: 'body',
    type: 'dynamic_content',
    label: 'Body',
    isRequired: true,
  },
  {
    name: 'utm_source',
    type: 'string',
    label: 'UTM Source',
    isRequired: true,
    validatePattern: /^[^*|\":<>?=[\]{}`\\()';@&$"  *"]+$/g,
  },
  {
    name: 'utm_medium',
    type: 'string',
    label: 'UTM Medium',
    isRequired: true,
    validatePattern: /^[^*|\":<>?=[\]{}`\\()';@&$"  *"]+$/g,
  },
  {
    name: 'utm_campaign',
    type: 'string',
    label: 'UTM Campaign',
    isRequired: true,
    validatePattern: /^[^*|\":<>?=[\]{}`\\()';@&$"  *"]+$/g,
  },
]

export default webPush
